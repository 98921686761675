.merge-confirmation-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    height: 70px;
    width: 104px;
    padding-top: 38px;
    margin-top: 50%;
  }

  .text-wrapper {
    padding: 20px;
  }

  .merge-confirmed {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .merge-confirmation {
    .confirm-btns-container {
      display: flex;
      justify-content: center;

      .confirm-btns-wrapper {
        display: flex;
        width: 75%;
        justify-content: space-evenly;
      }
    }
  }
}
