* {
  scrollbar-width: thin;
  scrollbar-color: #828282 #fafafa;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #fafafa;
}

*::-webkit-scrollbar-track:hover {
  background-color: #fafafa;
}

*::-webkit-scrollbar-track:active {
  background-color: #fafafa;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #828282;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #828282;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #828282;
}

.add-user-form-wrapper {
  position: relative;
  top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .relationship-deceased-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 25px;

      .invite-user-wrapper {
        display: flex;
        align-items: center;
        width: 100%;

        input {
          margin: 0px 0px 0px 20px;
          width: auto;
        }
      }

      .relationship-wrapper {
        display: flex;
        align-items: center;
        width: 88%;

        select {
          font-size: 14px;
          font-family: "Poppins", sans-serif;
          font-weight: 300;
          width: 100%;
          margin-left: 20px;
          border-radius: 10px;
          padding: 5px;
          border: 1px solid $medium-grey;
        }
      }

      .disclaimer {
        margin-top: 1em;
        font-size: 0.8em;
        font-weight: 600;
        color: $green;
      }

      .deceased-check {
        display: flex;
        align-items: center;
        width: 88%;

        input {
          margin: 0px;
          margin-left: 9px;
          width: 10%;
        }
      }
      .death-date-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: -webkit-fill-available;
        flex-direction: column;

        input {
          width: 100%;
        }
        .death-date {
          text-align: center;
        }
        .death-error {
          color: red;
          font-size: 13px;
          padding-bottom: 10px;
        }
      }
    }

    .success-message {
      color: green;
      padding-top: 10px;
    }

    .confirm-message {
      text-align: center;
      margin-top: 1em;
      font-size: 1em;
      font-weight: 600;
      color: red;
    }

    .fail-error {
      color: red;
      padding-top: 10px;
    }
    .birthdate-error {
      color: red;
      font-size: 13px;
    }
    .parents-error {
      color: red;
      font-size: 13px;
      padding-top: 10px;
    }
    .spouse-error {
      color: red;
      font-size: 13px;
      padding-top: 10px;
    }
  }
}

.wrapper {
  .form-field-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    .form-wrapper {
      width: 70%;
      margin-top: 70px;
      label {
        text-align: start;
      }
      input {
        display: flex;
        height: 100%;
        border: 1px solid #c4c4c4;
        border-radius: 5px 5px 5px 5px;
        margin-top: 2px;
        font: inherit;
        font-family: Poppins;
      }
      select {
        width: 100%;
        height: 69px;
        border: 1px solid #c4c4c4;
        border-radius: 5px 5px 5px 5px;
        margin-top: 2px;
        padding: 0px 10px;
        font: inherit;
        font-family: Poppins;
      }

      .user-form-buttons {
        display: flex;
        width: 100%;
        align-items: center;
        margin-top: 25px;

        .cancel-button {
          margin-right: 25px;
        }
      }
    }
  }
}
