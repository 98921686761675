@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

.email-verification-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10% 0 10%;
  background-color: #fafafa;

  img {
    height: 108px;
    width: 162px;
    padding-top: 38px;
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10%;

    h2 {
      text-align: center;
      font-family: "Poppins", sans-serif;
      font-size: 25px;
    }

    p {
      text-align: center;
      color: #707070;
    }

    input {
      padding-left: 10px;
      height: 40px;
      width: 300px;
      border-radius: 10px;
      border: 1px solid black;
      font-family: "Poppins", sans-serif;
      margin-bottom: 20px;
    }
    form {
      display: grid;
      grid-template-rows: 1fr 1fr;
      justify-items: center;
    }

    a {
      text-decoration: none;
    }
  }
}

@media (min-width: 768px) {
  .email-verification-wrapper {
    form {
      flex-direction: row !important;
    }
  }
}
