.body-wrapper {
  margin: 15px 0px;
  width: 100%;
}

.remove-family-modal {
  margin: 50px;
  h2 {
    font-weight: 500;
    padding-bottom: 30px;
  }

  .individual-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    font-size: 18px;

    .personal-info {
      display: flex;
      align-items: center;

      img {
        max-width: 80px;
        border-radius: 50%;
        margin-right: 20px;
      }
    }
  }

  .confirm-remove {
    text-align: center;
    button {
      margin: 10px 5px 0 5px;
    }
    .yes-btn {
      background-color: $lava;
      border: none;
    }
  }
  .removal-msg {
    text-align: center;
    div {
      font-size: 24px;
      padding-bottom: 20px;
    }
  }
}

.remove-btn {
  margin-left: 5px;
}

.home-wrapper {
  padding-top: 10px;
  .images-wrapper {
    width: 100vw;
    height: 276px;

    .avatar-img {
      position: relative;
      border-radius: 50%;
      height: 250px;
      width: 250px;
      margin-left: 75px;
      margin-top: 75px;
    }
  }

  .home-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .profile-info-wrapper {
      width: 85%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .profile-name {
        margin-top: 75px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h1 {
          font-size: 30px;
          font-weight: 500;
          color: #605b56;
          margin: 0px;
        }

        h2 {
          margin: 0px 10px 0px 0px;
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
    .personal-info-wrapper {
      .relationship-contact-info {
        display: flex;
        margin: 20px 0;
      }

      .merge-invite-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10%;

        .invite-user-button {
          margin-right: 5%;
        }

        .invited {
          color: green;
          padding-left: 8px;
        }
      }

      a {
        color: #18686f;
        text-decoration: none;

        &:hover {
          cursor: pointer;
        }

        .parents-wrapper {
          width: 25%;
          display: flex;
          justify-content: space-between;
          align-content: center;
          padding-left: 100px;
        }
      }
    }
    .about-section {
      width: 84vw;
      background-color: white;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.21);

      .link-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      a {
        color: black;
        font-size: 18px;
      }

      h1 {
        font-size: 18px;
        font-weight: 500;
        color: #605b56;
        margin: 0px;
      }

      p {
        color: #605b56;
        font-weight: 300;
        margin: 0px;
      }
    }

    .family-buttons {
      align-self: flex-end;
      margin-right: 6.5%;
      margin-top: 50px;
      margin-bottom: 25px;
    }

    .parents-wrapper {
      width: 84vw;
      background-color: white;
      padding: 20px 20px;
      border-radius: 5px;
      box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.21);

      .family-member-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 15px;

        img {
          width: 102px;
          height: 102px;
          border-radius: 50%;
        }
        .family-label {
          margin: 0;
        }
      }
    }

    .cards {
      padding-bottom: 100px;
      .cards-wrapper {
        display: grid;
        grid-template-rows: auto;
        row-gap: 50px;

        a {
          text-decoration: none;
          color: $dark-grey;
          &:visited {
            color: $dark-grey;
          }
        }
      }
    }

    .title-wrapper {
      display: flex;
      flex-direction: column;

      h1 {
        margin: 0px;
      }

      button {
        width: 100px;
      }

      .people {
        display: flex;
        margin-top: 25px;
      }
    }
  }

  h1 {
    font-size: 18px;
    font-weight: 500;
    color: #605b56;
    margin-bottom: 40px;
  }
}
