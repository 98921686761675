.verified-icon {
  width: 30px;
}
.verified-check-mark img {
  filter: invert(56%) sepia(57%) saturate(385%) hue-rotate(54deg)
    brightness(92%) contrast(85%);
}
.verified-x-mark img {
  filter: invert(29%) sepia(100%) saturate(1549%) hue-rotate(352deg)
    brightness(94%) contrast(88%);
}
