.signup-confirmation-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .logo-wrapper {
    width: 117px;
    margin: 25px 0 75px 0;

    img {
      height: 100%;
      width: 100%;
    }
  }
  .login-button-wrapper {
    padding-top: 70px;
  }
}
@media (min-width: 768px) {
  .signup-confirmation-page-wrapper {
    .logo-wrapper {
      width: 162px;
    }
    .confirmation-text-wrapper {
      p {
        height: 249px;
        width: 23px;
        font-size: 40px;
      }
    }
    .login-button-wrapper {
      display: flex;
      flex-direction: center;
      justify-content: center;
      align-items: center;
    }
  }
}
