.family-list-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0px;

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
    margin-bottom: 25px;
    width: 100vw;
    height: 200px;
    background-color: #18686f;
    color: $light-primary;

    h1 {
      text-align: center;
      font-size: 35px;
    }
  }

  .list-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0px;

    .create-btn {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    h3 {
      font-weight: 400;
      font-size: 16px;
      margin: 20px 0px 5px 5px;
      width: 200px;
    }

    .list-item-wrapper {
      margin-right: 10px;
      margin-bottom: 10px;
      width: 300px;
      height: 125px;
      display: flex;
      justify-content: center;
      align-items: center;
      // color: white;
      border-radius: 10px;
      background-color: #18686f;

      a {
        text-decoration: none;
      }

      .family-list-item {
        h1 {
          font-size: 16px;
          font-weight: 400;
          color: $light-primary;
        }
      }
    }

    .desktop-lists {
      display: none;
    }
  }
}

.create-list-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 5%;
  overflow-y: scroll;

  .form-field {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    input {
      border-radius: 10px;
    }

    .banner-image {
      height: 50px;
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    .file-upload-banner-image {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      padding-left: 75px;
    }
  }
}

@media (min-width: 280px) {
  .family-list-content-wrapper {
    width: 280px;

    .list-wrapper {
      // .create-btn-wrapper {
      //   .create-btn {
      //     height: 50px;
      //     width: 120px;
      //     font-size: 12px;
      //   }
      // }

      h3 {
        font-size: 25px;
        margin-top: 50px;
      }

      .mobile-lists {
        .family-list-item {
          width: 250px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          border-radius: 10px;

          h1 {
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            vertical-align: middle;
            padding-top: 18px;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .family-list-content-wrapper {
    .list-wrapper {
      // .create-btn-wrapper {
      //   .create-btn {
      //     height: 80px;
      //     width: 210px;
      //     font-size: 22px;
      //   }
      // }

      .custom-lists-title {
        h3 {
          margin-top: 50px;
          width: 500px;
        }
      }

      h3 {
        font-size: 35px;
        margin-top: 50px;
        width: 300px;
      }

      .mobile-lists {
        .family-list-item {
          margin-top: 10px;
          width: 600px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          border-radius: 10px;

          h1 {
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
  }
}

@media (min-width: 1025px) {
  .family-list-content-wrapper {
    display: flex;
    flex-direction: column;

    .title {
      h2 {
        font-size: 35px;
      }
    }

    .list-wrapper {
      width: 80vw;

      // .create-btn-wrapper {
      //   padding-left: 42px;

      //   .create-btn {
      //     text-align: center;
      //     font-size: small;
      //     width: 150px;
      //     height: 40px;
      //   }
      // }

      .desktop-lists {
        display: block;
        width: 80vw;
      }

      .mobile-lists {
        display: none;
      }

      .family-list-item {
        width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        border-radius: 10px;

        h1 {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }
}
