.wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    height: 108px;
    width: 162px;
    margin-bottom: 30px;
    align-self: center;

    position: relative;
    right: 3px;
  }

  .login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;

    h2 {
      color: #665;
    }

    .form-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;

      button {
        width: 80%;
      }
      
      .form {
        width: 100%;

        .input-wrapper {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
        }

        .alternative-link-wrapper {
          display: flex;
          justify-content: space-evenly;
          font-size: 0.7em;
          margin-top: 30px;
        }

        .no-decoration {
          color: $dark-grey;
        }
      }
      .login-error-message {
        color: $lava;
      }
    }
  }
}

@media (min-width: 320px) {
  .alternative-link-wrapper {
    font-size: 0.8em !important;

    #no-account {
      text-align: right;
    }
  }
}

@media (min-width: 350px) {
  .alternative-link-wrapper {
    font-size: 0.9em !important;
  }
}

@media (min-width: 400px) {
  .alternative-link-wrapper {
    font-size: 0.9em !important;
  }
}

@media (min-width: 500px) {
  .login-wrapper img {
    height: 40%;
    width: 40%;
  }
}

@media (min-width: 700px) {
  .wrapper {
    align-items: center;
    width: 100vw;
    margin: 0px;
    
    .login-wrapper {
      width: 60vw;
      align-items: center;
      
      .form-wrapper {
        
        form {
          display: block;
         
          .input-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            
            input {
              width: 75%;
            }
          }
        }
        .alternative-link-wrapper {
          width: 45vw;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
