* {
  scrollbar-width: thin;
  scrollbar-color: #828282 #fafafa;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #fafafa;
}

*::-webkit-scrollbar-track:hover {
  background-color: #fafafa;
}

*::-webkit-scrollbar-track:active {
  background-color: #fafafa;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #828282;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #828282;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #828282;
}

.add-user-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 20px;
  }

  h1 {
    font-size: 20px;
    font-weight: 300;
  }

  input {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    width: 70%;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 5px;
    border: 1px solid $medium-grey;
  }

  button {
    margin-top: 30px;
  }

  .successful-msg {
    color: $green;
  }
  .unsuccessful-msg {
    color: $lava;
  }
  .results {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .user-item-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60%;
      margin-bottom: 5%;

      .search-result-item-container {
        width: 95%;
        grid-template-columns: 1fr 2fr 1fr;

        h2 {
          font-size: 18px;
          text-align: center;
        }

        .avatar-image-wrapper {
          margin-left: 5%;
        }

        .search-item-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 15%;
          padding-top: 5%;
        }
      }
    }
  }
}

.exit-button {
  position: absolute;
  right: 2%;
  top: 2%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
