.content-page-wrapper {
  display: flex;
  flex-direction: row;
  padding-top: 75px;
  width: 100%;

  .avatar-wrapper {
    display: flex;
    justify-content: center;
    margin-left: 10%;

    img {
      width: 400px;
      height: 400px;
      border-radius: 10px;
    }
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;

    h2 {
      font-size: 24px;
    }

    .page-button-wrapper {
      display: flex;
      flex-direction: column;
      width: 50%;
      align-items: center;
      justify-content: center;
      padding-top: 25px;

      .back-save-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
      }
    }

    .info-wrapper {
      width: 60%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }

    .contact-methods-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;

      span {
        width: 15%;
        border-bottom: 1px solid #707070;
        margin: 0px 8px 0px 8px;
      }
    }

    .allow-contact-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      h1 {
        margin: 0px 4px 0px 10px;
      }
    }

    .action-buttons-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      h1 {
        font-weight: 500;
      }

      .send-message-btn {
        margin-top: 25px;
        width: 244px;
        height: 43px;
        font-size: 16px;
        text-align: center;
        vertical-align: middle;
      }
    }

    .social-media-icons {
      width: 70%;
      padding: 15px 0px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      i {
        font-size: 25px;
      }
    }
  }
}

h2 {
  font-weight: 200;
  font-size: 18px;
  text-align: center;
}

h1 {
  font-weight: 200;
  font-size: 9px;
}
