.activity-log {
  text-align: right;
  width: 100vw;


  .activity-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
    margin-bottom: 75px;
    width: 100vw;
    height: 200px;
    background-color: #18686f;
    color: $light-primary;

    h1 {
      text-align: center;
      font-size: 35px;
    }
  }

  .date-events {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;
    margin-left: 12.5%;

    ul {
      text-align: left;
      width: 100%;
      list-style-type: none;
      padding: 0%;
    }
  }

  .cards {
    .card-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .event {
        display: flex;
        align-items: center;
      }

      .x-button {
        padding: 5px;
      
        button {
          width: 40px;
        }
      }
    }

    li {
      padding: 10px;
      background-color: white;
      margin: 16px;
      align-content: center;
      border-radius: 5px;
      box-shadow: 0px 0px 4px 3px rgb(0 0 0 / 21%);

      h1 {
        font-size: 20px;
        margin: 0px;
      }

      h2 {
        padding-left: 10px;
        margin: 0px;
        text-align: left;
        font-size: 1.25rem;
      }
    }
  }
}