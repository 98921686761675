.password-change-page-wrapper {
  height: 100vh;
  width: 100vw;
  position: relative;
  top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .change-form {
    position: relative;
    top: 15px;
    height: 100%;
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    .new-password {
      width: 100%;
      height: 35px;
      border: 2px solid #7ebdc3;
      border-radius: 10px;
      padding-left: 10px;
    }
    .top-password {
      margin-bottom: 20px;
    }
    .change-password-button {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      width: 105%;
      height: 45px;
      font-family: "Poppins", sans-serif;
      font-weight: 300;
    }
    .password-error {
      width: 100%;
      color: $lava;
      text-align: left;
    }
  }

  @media (min-width: 768px) {
    .pc-logo {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .change-form {
      width: 40vw;
    }
  }
  @media (min-width: 1024px) {
    .change-form {
      width: 30vw;
    }
  }
}
