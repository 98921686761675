.delete-user-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  h1 {
    font-size: 1.5em;
    margin-bottom: 2em;
  }
}
