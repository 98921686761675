.align-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banner-image {
  h1 {
    max-height: 0%;
    max-width: 100%;
    align-items: center;
    font-size: 16px;
    margin-bottom: 2px;
  }
}