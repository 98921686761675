.merge-form-wrapper {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 25px;
    font-weight: 200;
  }

  .merge-items-wrapper {
    max-height: 100%;
    overflow-x: scroll;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: 1rem;
      margin-top: 30px;
    }

    .merge-item {
      width: 95%;
      margin-top: 30px;
      padding-bottom: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 200px;
      background-color: white;
      border-radius: 5px;
      -webkit-box-shadow: 0px 5px 8px 1px rgba(145, 145, 145, 0.41);
      box-shadow: 0px 5px 8px 1px rgba(145, 145, 145, 0.41);

      img {
        width: 57px;
        height: 57px;
        border-radius: 50%;
      }

      .member-wrapper {
        position: relative;
        top: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
          font-size: 14px;
        }
      }
    }
  }
}
