h3 {
  font-weight: 400;
  font-size: 16px;
  margin: 20px 0px 5px 5px;
}

.carousel-wrapper {
  height: 152px;
  display: flex;
  align-items: center;

  .left-scroll {
    margin: 0 10px;
    font-size: 45px;
    font-weight: 400;
    user-select: none;
    border-radius: 11px;

    &:hover {
      cursor: pointer;
      box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
        0 17px 50px 0 rgba(0, 0, 0, 0.19);
    }
  }

  .right-scroll {
    margin: 0 10px;
    font-size: 45px;
    font-weight: 400;
    user-select: none;
    border-radius: 11px;

    &:hover {
      cursor: pointer;
      box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
        0 17px 50px 0 rgba(0, 0, 0, 0.19);
    }
  }

  .carousel {
    min-width: 0%;
    max-width: 100%;
    display: grid;
    align-content: center;
    grid-auto-flow: column;
    overflow-x: scroll;
    scroll-behavior: smooth;
  }
}

h1 {
  margin: 0;
}
