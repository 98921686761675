.social-media-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;

  img {
    height: 100px;
  }

  h2 {
    text-align: center;
    font-size: 2rem;
  }

  .form-field-wrapper {
    width: 60%;
  }

  .form-wrapper {
    position: relative;
    top: 20px;
    width: 100%;
    margin-bottom: 20%;

    .back-button-wrapper {
      width: 60%;
    }
    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .message-wrapper {
        height: 30px;
        width: 69%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        h1 {
          font-size: 16px;
          color: $green;
        }
      }

      .social-media-item-wrapper {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        align-items: center;
      }

      label {
        font-size: 40px;
      }

      input {
        border: none;
        padding: 3px;
        padding-left: 20px;
      }

      .confirm-button {
        font-weight: 300;
        font-size: 14px;
        height: 40px;
        align-self: center;
        margin-left: 20px;
      }
    }
  }
}

@media (max-width: 700px) {
  .social-media-page {
    width: 100vw;
    .form-field-wrapper {
      width: 100%;
      .form-wrapper {
        width: 100%;
      }
    }
    label {
      align-self: start;
      i {
        font-size: 45px;
      }
    }
    .social-media-item-wrapper {
      .MuiInputBase-root {
        width: 180px;
        align-self: left;
      }
    }
  }
}

@media (max-width: 375px) {
  .social-media-page {
    .form-field-wrapper {
      h2 {
        font-size: 30px;
      }
    }
    .back-button-wrapper {
      width: 83% !important;
      .back-button {
        font-size: 10px !important;
      }
    }
    label {
      i {
        display: none;
      }
    }
    .social-media-item-wrapper {
      width: 100% !important;
      display: grid;
      grid-template-columns: 0fr 3fr 1fr !important;
      align-items: center;
    }
  }
}

@media (max-width: 320px) {
  .social-media-page {
    .confirm-button {
      position: relative !important;
      left: 30px !important;
      width: 70px !important;
    }
  }
}
