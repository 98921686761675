.timeout-confirmation-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .timeout-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 400px;

    p {
      text-align: left;
      width: 380px;
      height: 40px;
      padding: 10px;
    }
    h1 {
      width: 380px;
      height: 40px;
      padding: 10px;
      font-size: 16px;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
    }
  }

  .logo-wrapper {
    width: 117px;
    margin: 25px 0 75px 0;

    img {
      height: 100%;
      width: 100%;
    }
  }
  .login-button-wrapper {
    .login-wrapper {
      width: 341px;
      .login {
        width: 100%;
        height: 53px;
        text-align: left;
        margin: 75px 0 75px 0;
      }
    }
  }
}
@media (min-width: 768px) {
  .timeout-confirmation-page-wrapper {
    .logo-wrapper {
      width: 162px;
    }
    .timeout-text-wrapper {
      p {
        font-size: 16px;
      }
    }
    .login-button-wrapper {
      display: flex;
      flex-direction: center;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (min-width: 375px) {
  .timeout-confirmation-page-wrapper {
    .timeout-text-wrapper {
      margin: 25px 0 25px 0;
      p {
        width: 80%;
      }
    }
  }
}

@media (max-width: 360px) {
  .timeout-confirmation-page-wrapper {
    padding: 0px;
    padding-left: 3%;
    margin: 0px;
    .timeout-text-wrapper {
      p {
        font-size: 12px;
        width: 80vw;
      }
    }
    .login-button-wrapper {
      .login-wrapper {
        .login {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .timeout-confirmation-page-wrapper {
    padding-left: 7%;
  }
}
