@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.signup-page-wrapper {
  padding-bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .signup-page-logo-wrapper {
    width: 171px;
    margin: 60px 0 48px 0;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .signup-text-wrapper {
    h1 {
      font-size: 25px;
      margin-bottom: 20;
      margin-top: 0;
      font-family: "Poppins", sans-serif;
      font-weight: 200;
    }
  }
  .form-wrapper {
    width: 100%;
    padding-top: 35px;

    .form {
      width: 454px;

      .name-input-wrapper {
        display: flex;
        justify-content: space-around;
        align-items: center;

        input {
          width: 300px;
          height: 10px;
          font-family: "Poppins", sans-serif;
          font-weight: 400;
          border-radius: 10px;
          // margin: 0;
        }
      }
      .other-input-wrapper {
        input[type="text"] {
          margin-top: 0;
        }

        .date-wrapper {
          display: flex;
          align-items: flex-start;
          align-items: center;

          label {
            flex: 0.6 1 0;
            text-align: center;
            font-size: 20px;
            font-weight: 200;
          }
          .date {
            flex: 1.4 1 0;
            font-family: "Poppins", sans-serif;
            opacity: 0.6;
            border: 1px solid black;
            font-size: 12px;
            &:focus {
              opacity: 1;
            }
          }
        }

        input {
          // margin-top: 27px;
          width: 426px;
          border-radius: 10px;
          height: 10px;
        }
        .phone-message {
          color: red;
          font-size: 0.75rem;
        }

      }

      .signup-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 22px;
        margin-bottom: 10px;
      }

      .bottom-link-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          font-family: "Poppins", sans-serif;
          font-weight: 400;
          font-size: 12px;
          padding-right: 5px;
          margin: 0;
        }

        a {
          color: $medium-grey;
          font-size: 12px;
          text-decoration: none;
          font-weight: 400;
          cursor: pointer;
          margin: 0;
          font-weight: 500;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .signup-page-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .signup-page-logo-wrapper {
      width: 118.02px;
      margin: 32.05px 0 19.38px 0;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .signup-text-wrapper {
      h1 {
        font-size: 20px;
        margin: 0;
        font-family: "Poppins", sans-serif;
        font-weight: 200;
      }
    }
    .form-wrapper {
      width: 100%;
      .form {
        width: 300px;
        .name-input-wrapper {
          display: flex;
          justify-content: center !important;
          align-items: center;

          input {
            width: 100px;
            border-radius: 10px;

            height: 20px;
          }
        }
        .other-input-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          input {
            margin-left: 0;
            width: 259px;
            height: 20px;
            border-radius: 10px;
          }
        }

        .signup-button-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 8px;

          &:hover {
            cursor: pointer;
          }
        }
        .bottom-link-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
          p {
            padding-right: 5px;
            font-size: 12px;
            margin: 10;
          }
          a {
            color: black;
            text-decoration: none;
            cursor: pointer;
            font-size: 12px;
            margin: 10;
          }
        }
      }
    }
  }
}
