@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.family-contact-wrapper {
  padding-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .family-contact-text-wrapper {
    h1 {
      font-size: 25px;
      margin: 0;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
    }
  }

  .form-wrapper {
    width: 100%;
    padding-top: 80px;

    .form {
      width: 454px;

      .side-by-side-input-wrapper {
        display: flex;
        justify-content: space-around;
        align-items: center;

        input {
          width: 213px;
          height: 30px;
          border-radius: 10px;
        }
      }

      .email-address-input-wrapper {
        input {
          width: 426px;
          height: 30px;
          border-radius: 10px;
        }
      }

      .location-input-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
          width: 90px;
          height: 30px;
          border-radius: 10px;
        }
      }

      .contact-methods-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        span {
          border-bottom: 1px solid black;
          width: 30%;
        }

        p {
          font-size: 16px;
          font-weight: 300;
          color: black;
        }
      }

      .mobile-phone-wrapper {
        width: 100%;

        input {
          width: 426px;
          height: 30px;
          border-radius: 10px;
        }
      }

      .save-contact-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .bottom-link-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          font-weight: bold;
          padding-right: 5px;
          margin: 0;
        }

        a {
          color: black;
          text-decoration: none;
          font-weight: bold;
          cursor: pointer;
          margin: 0;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .family-contact-wrapper {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .family-contact-logo-wrapper {
      width: 171px;
      margin: 14px 0 20px 0;
      display: flex;
      justify-content: center;

      img {
        height: 70px;
        width: 104px;
      }
    }

    .family-contact-text-wrapper {
      h1 {
        font-size: 25px;
        margin: 0;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
      }
    }

    .form-wrapper {
      width: 100%;

      .form {
        width: 80%;

        .side-by-side-input-wrapper {
          width: 80%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          input {
            width: 124px;
            height: 30px;
            border-radius: 10px;
          }
        }

        .email-address-input-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding-right: 20px;

          input {
            width: 100%;
            height: 30px;
            border-radius: 10px;
          }
        }

        .location-input-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;

          input {
            width: 50px;
            height: 30px;
            border-radius: 10px;
          }
        }

        .contact-methods-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          span {
            border-bottom: 1px solid black;
            width: 30%;
          }

          p {
            font-size: 16px;
            font-weight: 300;
            color: black;
          }
        }

        .mobile-phone-wrapper {
          width: 100%;
          display: flex;

          input {
            width: 100%;
            height: 30px;
            border-radius: 10px;
          }
        }

        .save-contact-button-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .bottom-link-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            font-weight: bold;
            padding-right: 5px;
            margin: 0;
          }

          a {
            color: black;
            text-decoration: none;
            font-weight: bold;
            cursor: pointer;
            margin: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .family-contact-wrapper {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .family-contact-logo-wrapper {
      width: 171px;
      margin: 14px 0 20px 0;
      display: flex;
      justify-content: center;

      img {
        height: 70px;
        width: 104px;
      }
    }

    .family-contact-text-wrapper {
      h1 {
        font-size: 25px;
        margin: 0;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
      }
    }

    .form-wrapper {
      width: 100%;

      .form {
        width: 80%;

        .side-by-side-input-wrapper {
          width: 80%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          input {
            width: 108px;
            height: 30px;
            border-radius: 10px;
          }
        }

        .email-address-input-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding-right: 20px;

          input {
            width: 100%;
            height: 30px;
            border-radius: 10px;
          }
        }

        .location-input-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;

          input {
            width: 50px;
            height: 30px;
            border-radius: 10px;
          }
        }

        .contact-methods-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          span {
            border-bottom: 1px solid black;
            width: 30%;
          }

          p {
            text-align: center;
            font-size: 16px;
            font-weight: 300;
            color: black;
          }
        }

        .mobile-phone-wrapper {
          width: 100%;
          display: flex;

          input {
            width: 100%;
            height: 30px;
            border-radius: 10px;
          }
        }

        .save-contact-button-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .bottom-link-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            font-weight: bold;
            padding-right: 5px;
            margin: 0;
          }

          a {
            color: black;
            text-decoration: none;
            font-weight: bold;
            cursor: pointer;
            margin: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .family-contact-wrapper {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .family-contact-logo-wrapper {
      width: 171px;
      margin: 14px 0 20px 0;
      display: flex;
      justify-content: center;

      img {
        height: 70px;
        width: 104px;
      }
    }

    .family-contact-text-wrapper {
      h1 {
        font-size: 25px;
        margin: 0;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
      }
    }

    .form-wrapper {
      width: 100%;

      .form {
        width: 80%;

        .side-by-side-input-wrapper {
          width: 80%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          input {
            width: 103px;
            height: 30px;
            border-radius: 10px;
          }
        }

        .email-address-input-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding-right: 20px;

          input {
            width: 100%;
            height: 30px;
            border-radius: 10px;
          }
        }

        .location-input-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-right: -12px;

          input {
            right: 20px;
            width: 60px;
            height: 30px;
            border-radius: 10px;
          }
        }

        .contact-methods-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          span {
            border-bottom: 1px solid black;
            width: 34%;
            margin-right: -5px;
          }

          p {
            text-align: center;
            font-size: 16px;
            font-weight: 300;
            color: black;
          }
        }

        .mobile-phone-wrapper {
          width: 100%;
          display: flex;

          input {
            width: 100%;
            height: 30px;
            border-radius: 10px;
            margin-right: -10px;
          }
        }

        .save-contact-button-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .bottom-link-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            font-weight: bold;
            padding-right: 5px;
            margin: 0;
          }

          a {
            color: black;
            text-decoration: none;
            font-weight: bold;
            cursor: pointer;
            margin: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .family-contact-wrapper {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .family-contact-logo-wrapper {
      width: 171px;
      margin: 14px 0 20px 0;
      display: flex;
      justify-content: center;

      img {
        height: 70px;
        width: 104px;
      }
    }

    .family-contact-text-wrapper {
      h1 {
        font-size: 25px;
        margin: 0;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
      }
    }

    .form-wrapper {
      width: 100%;

      .form {
        width: 80%;

        .side-by-side-input-wrapper {
          width: 80%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          input {
            width: 88px;
            height: 30px;
            border-radius: 10px;
          }
        }

        .email-address-input-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding-right: 20px;

          input {
            width: 100%;
            height: 30px;
            border-radius: 10px;
          }
        }

        .location-input-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;

          input {
            width: 50px;
            height: 30px;
            border-radius: 10px;
          }
        }

        .contact-methods-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          span {
            border-bottom: 1px solid black;
            width: 30%;
          }

          p {
            text-align: center;
            font-size: 16px;
            font-weight: 300;
            color: black;
          }
        }

        .mobile-phone-wrapper {
          width: 100%;
          display: flex;

          input {
            width: 100%;
            height: 30px;
            border-radius: 10px;
          }
        }

        .save-contact-button-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .bottom-link-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            font-weight: bold;
            padding-right: 5px;
            margin: 0;
          }

          a {
            color: black;
            text-decoration: none;
            font-weight: bold;
            cursor: pointer;
            margin: 0;
          }
        }
      }
    }
  }
}