.search-results-page-wrapper {
  width: 100vw;
  padding: 30px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    text-decoration: none;
  }

  .text-wrapper {
    border-bottom: 1px solid black;
    width: 100vw;
    display: flex;
    padding-left: 10px;

    h3 {
      width: 100%;
      color: #18686f;
      font-weight: 300;
      margin-bottom: 30px;
    }
  }
  .search-results-container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    margin-top: 30px;
    gap: 2%;
  }

  .no-results-wrapper .text {
    display: flex;
    flex-direction: column;
    align-content: center;

    .addbutton {
      padding-top: 10%;
    }
  }
}

@media (min-width: 768px) {
  .search-results-page-wrapper {
    .text-wrapper {
      h3 {
        font-size: 30px;
        padding-left: 35px;
      }
    }
  }
}
