.social-media-icons-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 38%;
  height: 150px;
  a {
    text-decoration: none;
    color: $dark-primary;
    font-size: 40px;
  }
}

@media (max-width: 768px){
  .social-media-icons-wrapper {
    width: 100%;
  }
}
