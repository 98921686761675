.invite-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding: 10px;

  .logo-wrapper {
    display: flex;
    justify-content: center;

    img {
      width: 114px;
      height: 76px;
    }
  }

  .text-wrapper {
    padding-top: 30px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $dark-secondary;

    p {
      font-size: 12px;
      font-weight: 300;
      text-align: center;
    }

    h1 {
      font-size: 12px;
      font-weight: 400;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .password {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5%;

        .enter-password {
          margin-right: 5%;
        }

        input {
          margin: 8px 0 0 8px;
          line-height: 2em;
          width: 300px;
          border-radius: 5px;
          border: 2px solid #18686f;
          height: 35px;
        }
      }

      button {
        width: 50%;
      }

      label {
        text-align: right;
        font-size: 16px;
        z-index: 2;
        margin-bottom: 2%;
      }
    }
  }
}

@media (min-width: 414px) {
  .invite-page-wrapper {
    .text-wrapper {
      p {
        font-size: 14px;
      }

      h1 {
        font-size: 14px;
      }
    }
  }
}

@media (min-width: 768px) {
  .invite-page-wrapper {
    .logo-wrapper {
      img {
        width: 162px;
        height: 108px;
      }
    }
    .text-wrapper {
      width: 60%;
      p {
        font-size: 25px;
      }

      h1 {
        width: 100vw;
        text-align: center;
        font-size: 29px;
      }
    }
  }
}
