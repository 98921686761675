.delete-modal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  width: 420px;
  padding: 15px;
  height: 250px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -150px;
  margin-top: -150px;
  outline: none;
  background-color: white;

  border-radius: 5px;
  box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.32);

  .are-you-sure {
    font-size: 23px;
    margin-right: 5px;
  }

  .icon {
    padding: 10px;
    font-size: 80px;
    color: $orange;
    justify-self: center;
  }
}
