.body-wrapper {
  display: flex;
  justify-content: center;
  width: 100vw;
}
.page-wrapper {
  padding: 0px;
  .to-subject-wrapper {
    .to-wrapper {
      padding-top: 10px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #707070;

      h2 {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 400;
      }

      input {
        border: none;
        background-color: #fafafa;
        width: 100%;
      }
    }
    .subject-wrapper {
      padding-top: 10px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #707070;

      input {
        border: none;
        background-color: #fafafa;
        width: 100%;
      }
    }
  }
  .message-send-btn-wrapper {
    padding-top: 10px;
    .message-body-wrapper {
      width: 100%;

      textarea {
        border-radius: 10px;
        height: 40vh;
        width: 80vw;
        font-family: "Poppins", sans-serif;
      }
    }
    .btn-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      padding-top: 10px;

      h1 {
        margin-top: 10px;
        font-size: 14px;
        color: $green;
      }

      .error-message {
        color: $lava;
      }
    }
  }
}

@media (min-width: 540px) {
  .page-wrapper {
    .to-subject-wrapper {
      width: 80vw;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .to-wrapper {
        width: 40%;
      }

      .subject-wrapper {
        width: 40%;
      }
    }
  }
}

@media (min-width: 1025px) {
  .body-wrapper {
    display: flex;
    justify-content: center;
    width: 100vw;
  }
  .page-wrapper {
    display: flex;
    flex-direction: column;
    width: 40vw;

    .message-send-btn-wrapper {
      .message-body-wrapper {
        textarea {
          width: 100%;
          height: 50vh;
        }
      }
    }
  }
}

h1 {
  font-weight: 400;
}

input {
  outline: none !important;
}
