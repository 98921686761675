.wrapper {
  .form-field-wrapper {
    .form-wrapper {
      padding-top: 40px;
      padding-bottom: 40px;

      .form {
        .birthdate-error {
          color: red;
        }

        .invite-user-wrapper {
          display: flex;
          align-items: center;
          align-self: center;
          justify-content: space-evenly;
          width: 66%;
        }

        .deceased-check {
          display: flex;
          align-items: center;
          align-self: center;
          justify-content: space-evenly;
          width: 66%;
        }

        .death-date-wrapper {
          align-self: center;
          width: 66%;

          .death-date {
            display: flex;
            flex-direction: column;
            align-items: center;

            label {
              font-size: 14px;
            }

            input {
              height: 90%;
            }

            .death-error {
              color: red;
            }
          }
        }

        .activity-status-wrapper {
          display: flex;
          flex-direction: column;
        }

        .user-form-buttons {
          display: flex;
          width: 50%;
          align-self: center;
          justify-content: space-between;
        }
      }
    }
  }
}
