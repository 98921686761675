.navbar-wrapper {
  height: 74px;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 30px 0px 0px 20px;

  .logo-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    img {
      width: 61px;
      height: 41px;
    }
  }

  .navbar-left {
    display: flex;
    justify-content: center;
    width: 40%;
    height: 100%;

    .search-bar-wrapper {
      width: 60%;
      padding-top: 6px;

      &:hover {
        box-shadow: 10px 10px 5px grey transparent;
      }

      .search-bar {
        width: 100%;
        height: 40px !important;
        border: 1px solid black;
        border-radius: 10px;


        &::placeholder {
          font-family: "Font Awesome\ 5 Free", Arial, Helvetica, sans-serif;
          font-size: small;
          font-weight: bold;
        }
      }
    }
  }

  .navbar-right {
    width: 60%;
    display: flex;
    align-items: flex-end;

    .nav-links {
      width: 70%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      transition: text-decoration 1s 1s;

      .nav-link {
        background: $light-grey;
        color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        margin: 0px 20px;
      }

      :hover {
        cursor: pointer;
        text-decoration: underline;
        box-shadow: 10px 10px 5px grey transparent;
        opacity: 0.6;
      }
    }

    .logout-button {
      width: 30%;
      display: flex;
      justify-content: center;

      button {
        width: 60%;
      }
    }

    .avatar {
      width: 15%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      position: relative;

      img {
        width: 2em;
        height: 2em;
        border-radius: 50%;

        &:hover {
          cursor: pointer;
        }
      }

      .avatar-text:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .menu-button {
      background: none;
      border: none;
      position: relative;
      top: 12px;
      cursor: pointer;
    }

    button.search-button {
      border-radius: 0px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border: none;
      margin-left: 0px;
      height: 29px;
    }

    .users_name {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 100px;
      height: 100%;
      color: black;
      font-size: 1.1rem;
      margin-left: 30px;
      margin-right: 30px;
      cursor: pointer;
    }

    .login-logout-button {
      .active {
        background-color: white;
      }
    }

    .dropdown {
      z-index: 2;
    }

    .dropdown-menu {
      width: 300px;
    }

    .dropdown-menu-wrapper {
      position: absolute;
      right: 45px;
      top: 50px;
      width: 190px;
      background-color: white;
      color: black;
      border: 1px solid $light-grey;
      padding: 20px;
      padding-top: 0px;
      line-height: 1.3em;
      cursor: pointer;
      outline: none;
      -webkit-box-shadow: 0px 1px 4px 0px rgba(62, 81, 104, 0.49);
      -moz-box-shadow: 0px 1px 4px 0px rgba(62, 81, 104, 0.49);
      box-shadow: 0px 1px 4px 0px rgba(62, 81, 104, 0.49);

      h3 {
        margin-bottom: 10px;
      }

      .link {
        color: white;
        text-decoration: none;
        cursor: pointer;
      }

      .link:hover {
        color: $dark-primary;
      }

      hr {
        border: 1px solid $light-grey;
      }
    }

    .active {
      background-color: white;
      vertical-align: center;
      text-decoration: none;
      color: $dark-primary;
    }
  }

  @media (min-width: 540px) {
    .logo-wrapper {
      img {
        width: 70px;
        height: 47px;
      }
    }

    .search-bar {
      border: 1px solid $dark-grey;
      padding-left: 10px;

      &:focus {
        outline: none;
      }
    }
  }

  @media (min-width: 768px) {
    .logo-wrapper {
      img {
        width: 87px;
        height: 57px;
      }
    }
  }

  @media (min-width: 1100px) {
    padding-top: 10px;
    align-items: center;

    .logo-wrapper {
      padding-top: 5px;
    }

    a.logo-wrapper {
      height: 10% !important;
      width: 10% !important;
    }

    .navbar-left {
      margin-top: 15px;
      align-items: center;
    }
  }
}

.close {
  position: relative;
  left: 98%;
  bottom: 3%;
  background-color: transparent;
  border: none;

  &:hover {
    background-color: transparent;
    border: none;
  }
}