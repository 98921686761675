.no-match-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .text-wrapper {
    h2 {
      font-size: 45px;
      color: #605b56;
    }
    h1 {
      font-size: 20px;
      color: #605b56;
      font-weight: 200;
    }
  }

  .button-wrapper {
    position: relative;
    top: 100px;
  }

  @media (min-width: 768px) {
    .text-wrapper {
      h2 {
        font-size: 75px;
      }
      h1 {
        font-size: 35px;
      }
    }
  }
}
