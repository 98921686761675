.dropdown {
  position: absolute;
  top: 110%;
  width: 200px;
  transform: translateX(-45%);
  background-color: $dark-primary;
  border-radius: 5px;
  padding: 1rem;
  overflow: hidden;
  z-index: 5;
}

.menu {
  width: 100%;

  .menu-item {
    height: 25px;
    color: $light-grey;
    text-decoration: none;
    display: flex;
    align-items: center;
    border-radius: 10px;
    transition: background-color 500ms;
    padding: 0.75rem;

    &:hover {
      background-color: lighten($dark-primary, 5%);
    }

    &:nth-last-child(2) {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    &:last-child {
      padding-block: 10px;
      border-top: 1px solid $light-grey;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
  }
}
