.signup-confirmation-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .logo-wrapper {
    width: 35%;
    margin: 100px 0 25px 0;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .confirmation-text-wrapper {
    width: 45%;
    p {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
}

@media (min-width: 768px) {
  .signup-confirmation-page-wrapper {
    .logo-wrapper {
      width: 15%;
    }
    .confirmation-text-wrapper {
      p {
        font-size: 1.3em;
      }
    }
  }
}
