.fp-page-wrapper {
  height: 100vh;
  .forgot-password-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      width: 100vw;
      padding: 40px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 117px;
        height: 78px;
      }
    }
    h1 {
      font-size: 20px;
    }
    h2 {
      margin: 10px;
      font-size: 14px;
    }
  }
}

@media (min-width: 768px) {
  .fp-page-wrapper {
    img {
      width: 50% !important;
      height: 90% !important;
    }
    h1 {
      font-size: 25px !important;
    }

    h2 {
      font-size: 20px !important;
    }
  }
}
