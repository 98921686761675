.security-page-container {
  padding-bottom: 10vh;

  .security-preferences-wrapper {
    padding-left: 200px;
    padding-right: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .security-title-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 35px;
      margin-bottom: 75px;
      width: 100vw;
      height: 200px;
      background-color: #18686f;
      color: $light-primary;

      h1 {
        text-align: center;
        font-size: 35px;
      }
    }

    .security-preference {
      width: 80%;
      display: flex;
      justify-content: space-between;
      h1 {
        font-size: 25px;
        font-weight: 500;
        text-align: left;
      }
      h2 {
        font-size: 20px;
        font-weight: 300;
        text-align: left;
      }
      .security-dropdown {
        width: 20%;

        select {
          margin-top: 25px;
          width: 100%;
          height: 35px;
          border-radius: 5px;
          border: 2px solid #18686f;
        }
      }
    }
    .security-dropdown {
      :hover {
        cursor: pointer;
      }
    }

    .security-saved {
      color: $green;
    }

    .save-btn {
      margin-top: 5%;
      margin-bottom: 4%;
    }
  }

  .blocked-users-wrapper {
    width: 100%;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 30px;
      font-weight: 500;
      text-align: center;
    }
    .block-content-wrapper {
      display: flex;
      flex-direction: column;
      width: 60%;
      margin-left: 200px;
      margin-right: 200px;
      p {
        text-align: center;
        padding-bottom: 20px;
      }

      .block-card {
        align-self: center;
        background-color: #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.28);
        width: 50vw;
        height: fit-content;

        h2 {
          font-size: 20px;
          font-weight: 400;
        }

        .top-section {
          display: flex;
          justify-content: space-around;
          align-items: flex-start;
        }
        .search-wrapper {
          height: 1px;
          margin: 17px 0px;
          input {
            font-size: 16px;
            width: 100%;
            height: 25px;
            border-radius: 5px;
            border: 2px solid #18686f;
          }
          .search-results {
            background-color: #c0c0c0;
            border-radius: 0px 0px 5px 5px;
            width: 100%;
            padding: 5px 0px;
            margin-top: -2%;
            position: relative;

            .search-option {
              width: 98%;
              padding-left: 2%;
              &:hover {
                background-color: #70707045;
                cursor: pointer;
              }
              h1 {
                text-align: left;
                font-size: 16px;
                font-weight: 300;
              }
            }
          }
        }
        .confirm-hidden {
          display: none;
        }
        .confirm-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 15px;

          .block-buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 15px;

            .block-yes-button {
              margin-right: 15px;
            }
          }

          #red-btn {
            background-color: $lava;
            padding: 11px 23px;
            border: none;
          }

          h1 {
            font-size: 14px;
          }
        }
        .blocked-user-list {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .list-item {
            align-self: center;
            width: 60%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            li {
              font-weight: 300;
            }

            h1 {
              font-size: 16px;
              font-weight: 400;
              color: #18686f;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .security-page-container {
    .security-preferences-wrapper {
      margin-left: 50px;
      margin-right: 50px;
      padding-left: 0px;
      padding-right: 0px;
      .security-preference {
        width: 100%;
        flex-direction: column;
        padding-bottom: 30px;
        .security-dropdown {
          display: flex;
        }
        select {
          margin-top: 5px;
        }
      }
    }

    .blocked-users-wrapper {
      padding-top: 15px;
      h1 {
        font-size: 30px;
        font-weight: 500;
        text-align: center;
      }
      .block-content-wrapper {
        display: flex;
        flex-direction: column;
        margin-left: 50px;
        margin-right: 50px;
        p {
          text-align: center;
          padding-bottom: 20px;
        }

        .block-card {
          width: 80vw;
        }
      }
    }
  }
}

@media (max-width: 414px) {
  .security-page-container {
    .blocked-users-wrapper {
      .block-content-wrapper {
        .block-card {
          .top-section {
            flex-direction: column;
            align-items: center;

            h2 {
              margin-bottom: 10px;
            }

            .search-wrapper {
              margin: 0px 0px 25px 0px;

              input {
                width: 66vw;
              }
            }
          }

          .blocked-user-list {
            .list-item {
              width: 90%;
            }
          }
        }
      }
    }
  }
}
