button {
  cursor: pointer;
  transition: ease-in-out 0.3s;
  background-color: $dark-grey;
  color: $light-primary;
  border: 2px solid $medium-grey;
  border-radius: 10px;
  width: 150px;
  height: 40px;
  text-align: center;
  font-size: small;

  &:hover {
    background-color: $medium-grey;
  }

  &:active {
    background: $light-grey;
  }
}

.single-list-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 100px;

  a {
    text-decoration: none;
  }

  .content-wrapper {
    padding-top: 0px;
    width: 100vw;
    display: grid;
    grid-gap: 0px;
    justify-content: center;

    .single-list-title {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 35px;
      margin-bottom: 75px;
      width: 100vw;
      height: 200px;
      background-color: #18686f;
      color: $light-primary;

      h1 {
        text-align: center;
        font-size: 35px;
      }
    }

    .searchbar {
      display: flex;
      justify-content: center;
      width: 75%;
      margin-left: 12.5%;

      input {
        width: 85vw;
        height: 54px;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        border-radius: 5px;
        border: 1px solid #7ebdc3;
        padding-left: 10px;
      }
    }

    .buttons-wrapper {
      display: flex;
      width: 80%;
      align-items: center;
      justify-content: space-evenly;
      margin-left: 10%;
      margin-top: 50px;
      margin-bottom: 50px;
    }

    .select-all-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid black;
      width: 75%;
      margin-left: 12.5%;

      h2 {
        font-size: 22px;
      }
    }

    .list-members-wrapper {
      position: relative;

      max-height: 400px;
      overflow-y: scroll;
      margin-left: 11%;

      h1 {
        font-size: 20px;
        font-weight: 300;
        margin-left: 5%;
        margin-top: 50px;
      }

      .search-result-item-container {
        margin-left: 1.5%;
        margin-top: 20px;
      }
    }
  }

  .no-list {
    font-size: 35px;
    font-weight: 300;
  }

  @media (min-width: 768px) {
    .banner-wrapper {
      max-height: 300px;
    }

    .content-wrapper {
      .select-all-wrapper {
        order: 4;

        #select-all-checkbox {
          width: 20px;
          height: 20px;
          margin-left: 24px;
        }

        h2 {
          margin-left: 8px;
        }
      }

      .list-members-wrapper {
        order: 5;
        max-height: 800px;
      }
    }
  }
}

.confirm-remove-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  h1 {
    width: 45%;
    font-size: 20px;
    margin-top: 5%;
    text-align: center;
    border-bottom: 1px solid black;
    margin-bottom: 1rem;
    padding-bottom: 1em;
  }

  .name-wrapper {
    display: flex;
    flex-direction: column;
    width: 45%;
    margin-bottom: 1rem;

    h2 {
      color: black;
    }
  }
}

.disable-delete {
  display: none;
}
