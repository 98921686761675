.landing-page-wrapper {
  height: 80%;
  padding: 5vh 5vw;

  .header-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 150px;
    margin-bottom: 150px;

    .left-side-wrapper {

      .famlinc-logo {
        width: 150px;
        height: 100px;
        margin-bottom: 20px;
        margin-left: 50px;
      }
    }
    .right-side-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 50px;

      .btn-dark {
        margin-bottom: 30px;
        padding-left: 80px;
        padding-bottom: 20;
      }
    }
  }

  .slogan-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px;

    .p-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 50px;

      p {
        font-size: 2em;
        width: 50%;
        margin: 0;
        white-space: nowrap;

        span {
          color: #306bac;
        }
      }

      .btn-dark {
        margin-top: 50px;
        padding: 0;
      }
    }
    
  

    .landing-page-image {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-left: 25%;
      
      
      .img-wrapper {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media (max-width: 1325px) {
  .p-wrapper {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 1024px) {
  .left-side-wrapper {
    height: 85% !important;
  }
  .slogan-wrapper {
    font-size: 0.8rem;

    .p-wrapper {
      margin-bottom: 10% !important;
    }
  }
}

@media (max-width: 850px) {
  .left-side-wrapper {
    height: 65% !important;
  }
}

@media (max-width: 540px) {
  .right-side-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    min-width: 275px;
    height: 50px;
  }

  .btn-dark {
    padding: 0 !important;
  }

  .header-wrapper {
    flex-direction: column;
    align-items: center !important;

    .left-side-wrapper {
      display: flex !important;
      justify-content: center !important;
      height: 60% !important;
      width: 100% !important;
    }
  }

  .slogan-wrapper {
    font-size: 0.6rem;
    align-items: center !important;

    .p-wrapper {
      min-width: 350px;
      align-items: center !important;

      br {
        display: none;
      }

      p {
        text-align: center !important;
        white-space: initial !important;
      }
    }

    .landing-page-main-content-wrapper {
      flex-direction: column-reverse !important;
      align-items: center;

      .link-wrapper {
        align-self: center !important;
        margin-top: 40px;
      }
    }
  }
}
