.all-family-members-page-wrapper {
  padding-top: 10px;

  .title-button-wrapper {
    .page-title {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 35px;
      margin-bottom: 75px;
      width: 100vw;
      height: 200px;
      background-color: #18686f;
      color: $light-primary;

      h1 {
        text-align: center;
        font-size: 35px;
      }
    }

    .button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;

      .general-list-buttons {
        display: flex;
        justify-content: space-evenly;
        margin-right: 50px;

        button {
          width: 200px;
          margin-left: 50px;
        }

        #family-list-dropdown {
          background-color: $dark-grey;
          color: $light-primary;
          border: 3px solid $medium-grey;
          border-radius: 10px;
          padding: 5px;
          width: 200px;
          height: 40px;
          margin: 0px;

          &:hover {
            background-color: $medium-grey;
            color: $light-primary;
          }
        }
      }

      .list-action-buttons {
        display: flex;
        justify-content: space-around;
        padding-bottom: 20px;

        button {
          width: 200px;
        }

        .create-list-button {
          margin-right: 50px;
        }
      }
    }
  }

  .search-wrapper {
    padding: 10px;
    margin-bottom: 15px;
    width: 60%;
    margin-left: 19%;
    margin: 20px auto 40px;

    input {
      padding-left: 10px;
      font-size: 16px;
      width: 96%;
      font-family: "Poppins", sans-serif;
      font-weight: 300;
      border: 1px solid black;
      border-radius: 10px;

      &:hover {
        background-color: white;
        color: black;
      }
    }
  }

  .table-wrapper {
    display: grid;
    padding: 10px 0px 50px 0px;
    max-height: 100vh;
    max-width: 100vw;
    overflow-y: scroll;
    overflow-x: scroll;
    width: 75%;
    margin-left: 12.5%;

    table {
      height: 100%;
      border-spacing: 0px;
      text-align: center;

      .table-title {
        background-color: #a1a1a145;
        width: 150px;
        user-select: none;

        i {
          display: none;
          margin-left: 1em;
        }
      }

      td {
        border: 0.5px solid #70707045;
      }
    }
  }

  .no-results {
    padding: 10px 10px 0px 2.5%;
    margin-left: 44%;
    margin-bottom: 100px;

    h1 {
      font-size: large;
    }
  }

  img {
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }

  input {
    :hover {
      cursor: pointer;
    }
  }

  .choose-file-button {
    cursor: pointer;
    width: auto;

    input :hover {
      cursor: pointer;
    }
  }

  @media (min-width: 375px) {
    .title-button-wrapper {
      display: block;

      .general-list-buttons {
        display: flex;
        flex-direction: row;
      }

      .list-action-buttons {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;

        padding-left: 3px;

        .selection-btn {
          width: 71px;
        }

        .selected-btn {
          margin-left: 5px;
        }
      }
    }
  }

  @media (min-width: 414px) {
    .title-button-wrapper {
      .list-action-buttons {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;

        .selection-btn {
          width: 100px;
        }

        .selected-btn {
          margin-left: 0px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .title-button-wrapper {
      #family-list-dropdown {
        width: 200px !important;
      }
    }

    .list-action-buttons {
      justify-items: center;
    }

    .search-wrapper {
      // display: flex;

      input {
        font-size: 16px;
        width: 50%;
        font-family: "Poppins", sans-serif;
        font-weight: 300;
        border: 1px solid black;
        border-radius: 10px;
        display: flex;
        padding: 10px;
        margin: 0 auto;
      }
    }
  }

  @media (min-width: 1025px) {
    .title-button-wrapper {
      .selected-btn {
        width: 150px;
      }
    }

    .table-wrapper {
      display: grid;
    }

    img {
      display: block;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
      height: 50%;
    }
  }
}
