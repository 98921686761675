.all-family-members-page-wrapper {
  .title-button-wrapper {
    .general-list-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;

      input {
        padding: 20px;
        width: 150px;
        visibility: hidden;
      }

      .image-upload {
        &::before {
          width: 150px;
          height: 40px;
          margin: 15px;
          margin-left: 10%;
          content: "Upload CSV File";
          color: #f2fbe0;
          background-color: $dark-grey;
          border: 2px solid $medium-grey;
          border-radius: 10px;
          padding: 11px 24px;
          cursor: pointer;
          font-size: small;
          visibility: visible;
          transition: ease-in-out 0.3s;
        }

        &:hover {
          &::before {
            background-color: $medium-grey;
          }
        }

        &:active {
          &::before {
            background: $light-grey;
          }
        }
      }

      .label-replace-csv-file::before {
        content: "Replace CSV File";
        padding: 11px 21px;
      }

      .remove-csv-button {
        margin-right: 34px;
      }
    }
  }

  .table-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: top;
    max-height: 100vh;
    overflow-x: scroll;
  }
}

@media (max-width: 414px) {
  .all-family-members-page-wrapper {
    width: 90%;
  }
}
