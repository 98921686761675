@import "theme.scss";
@import "modal.scss";
@import "header.scss";
@import "toggleSlider.scss";
@import "landingPage.scss";
@import "signupPage.scss";
@import "singupConfirmation.scss";
@import "loginpage.scss";
@import "activeBadge.scss";
@import "verifiedBadge.scss";
@import "detailsPage.scss";
@import "home.scss";
@import "forgotPassword.scss";
@import "timeout.scss";
@import "familyContact.scss";
@import "verificationThankYou.scss";
@import "emailVerification.scss";
@import "socialmedia.scss";
@import "encourageSignUp.scss";
@import "contactInfoProfile.scss";
@import "carousel.scss";
@import "familyList.scss";
@import "DropdownMenu.scss";
@import "navbarMobile.scss";
@import "socialMediaIcons.scss";
@import "allFamilyMembers.scss";
@import "deleteUserModal.scss";
@import "contactInfoProfileForm.scss";
@import "sendMessage.scss";
@import "emailSentPage.scss";
@import "checkEmail.scss";
@import "searchResults.scss";
@import "searchResultItem.scss";
@import "socialMediaIcons.scss";
@import "invitePage.scss";
@import "changePassword.scss";
@import "noMatch.scss";
@import "loading.scss";
@import "learnMore.scss";
@import "singleList.scss";
@import "createListForm.scss";
@import "createListModal.scss";
@import "addUserForm.scss";
@import "addUserToList.scss";
@import "activityLog.scss";
@import "securityPreferences.scss";
@import "manageCSV.scss";
@import "mergeUserForm.scss";
@import "confirmMerge.scss";
@import "toastNotifications.scss";
@import "merge-confirmation-container.scss";
@import "userForm.scss";

html,
body,
#root,
.App {
  margin: 0px;
  padding: 0px;
  background-color: #fafafa;
  height: 100%;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }
  overflow-x: hidden;
}

// .App {
//   height: 100vh;
// }
