.email-verification-thank-you-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10% 0 10%;
  background-color: #fafafa;

  img {
    height: 70px;
    width: 104px;
    padding-top: 38px;
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 121px;

    h2 {
      text-align: center;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-size: 25px;
    }
    a {
      text-decoration: none;
    }

    button {
      margin: 20px 0;
    }
  }
}
