.sidebar {
  max-height: 60px;
  overflow-x: unset;
  display: flex;
  align-items: center;

  .toggle-btn {
    padding-right: 20px;
    color: #605b56;

    i {
      font-size: 30px;
    }
  }

  .side-menu-list {
    display: none;
  }
}

.sidebar-active {
  width: 80vw;
  max-height: 60px;
  overflow-x: unset;
  background-color: #605b56;
  position: relative;
  z-index: 1000;
  animation-duration: 0.5s;
  animation-name: slidein;

  .toggle-btn {
    padding: 10px 0px 0px 10px;
    color: white;

    i {
      font-size: 30px;
    }
  }

  .sidebar-menu-active {
    height: 100vh;
    background-color: #605b56;
    display: block;
    list-style-type: none;
    padding-left: 0px;

    .search-bar {
      border: none;
      padding-left: 10px;
      margin-bottom: -15px;

      input {
        width: 93%;
        border: 1px solid white;
        border-radius: 10px 10px 0px 10px;
        font-family: "Poppins", sans-serif;

        &::placeholder {
          font-family: "Font Awesome\ 5 Free", Arial, Helvetica, sans-serif;
          font-size: small;
          font-weight: bold;
        }
      }
      label {
        font-size: 15px;
        font-weight: 300;
        color: #757575;
        position: relative;
        top: -25px;
        left: 20px;
        z-index: 1;
      }
    }

    li {
      border-top: 1px solid white;
      font-weight: 300;
      padding: 5px 0px;
    }
    a {
      padding-left: 10px;
      text-decoration: none;
      color: white;
    }
  }
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

@keyframes slideout {
  from {
    margin-left: 16%;
    width: 100%;
  }
  to {
    margin-left: 64%;
    width: 300%;
  }
}

@media (min-width: 540px) {
  .sidebar {
    .toggle-btn {
      i {
        font-size: 35px;
      }
    }
  }

  .sidebar-active {
    width: 40vw;
  }

  @keyframes slidein {
    from {
      margin-left: 100%;
      width: 300%;
    }

    to {
      margin-left: 47%;
      width: 100%;
    }
  }
}

@media (min-width: 1024px) {
  .sidebar {
    .toggle-btn {
      i {
        font-size: 45px;
      }
    }
  }

  .sidebar-active {
    width: 40vw;
  }

  @keyframes slidein {
    from {
      margin-left: 100%;
      width: 300%;
    }

    to {
      margin-left: 50.8%;
      width: 100%;
    }
  }
}
