.check-email-wrapper {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    height: 108px;
    width: 162px;
    margin-bottom: 30px;
  }

  h1 {
    font-size: 20px;
  }
}
