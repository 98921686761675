.badge {
  background-color: #cccccc;
  width: 75px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: black;
}

.badge-active {
  background-color: $green;
  color: white;
}
