// Light
$light-primary: #f2fbe0;
$light-secondary: #e9e0fb;

// Dark
$dark-primary: #605b56;
$dark-secondary: #565b60;

// Greys
$light-grey: #fafafa;
$dark-grey: #606060;
$medium-grey: #a0a0a0;

// Theme
$theme-primary: #e1fec6;
$theme-secondary: #e3c6fe;

// Special
$lava: #c42021;
$green: #6da34d;
$orange: #ee9433;
$disabled: #d0d0d0;

body {
  font-family: "Poppins", sans-serif;
}

button {
  cursor: pointer;
  transition: ease-in-out 0.3s;
  background-color: $dark-grey;
  color: $light-primary;
  border: 2px solid $medium-grey;
  border-radius: 10px;
  width: 150px;
  height: 40px;
  text-align: center;
  font-size: small;

  &:hover {
    background-color: $medium-grey;
  }

  &:active {
    background: $light-grey;
  }

  &:disabled {
    background-color: $medium-grey;
    border-color: lightgray;
    cursor: auto;
  }
}

.exit-button {
  button {
    width: 40px;
    height: 40px;
  }
}

.button-icon {
  margin-right: 10px;
  margin-bottom: 1px;
}

.body-wrapper {
  margin: 30px 50px;
  width: 100vw;
}

div.rdt_Table a {
  text-decoration: none;
  color: white;
}

div.rdt_Table a.table-link {
  color: $dark-secondary;
}

.flex-row {
  display: flex;
}

.detail-wrapper {
  margin-left: 100px;
}

p.address {
  font-size: 1.2em;
}

p.phone {
  font-size: 1.1em;
  color: $dark-secondary;
}

h2 {
  font-weight: 400;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.87);
}

a.no-decoration {
  text-decoration: none;
  color: $dark-primary;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;

  .form {
    display: flex;
    flex-direction: column;
    width: 80%;

    label {
      text-align: right;
      margin-top: 10px;
      margin-right: 10px;
    }

    label.drop-down-label {
      margin-top: 18px;
    }

    input {
      margin: 10px 0;
      padding: 15px;
      border: 1px solid $dark-primary;
      border-radius: 10px;

      &:focus {
        outline: none;
      }
    }
  }
}

.bordered-icon {
  border: 2px solid black;
  border-radius: 5px;
  margin-right: 5px;

  i {
    margin: -5px 5px;
  }
}

.list-page {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 80vh;
  width: 100vw;

  .admin-title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
    margin-bottom: 25px;
    width: 100vw;
    height: 200px;
    background-color: #18686f;
    color: $light-primary;

    h1 {
      text-align: center;
      font-size: 35px;
    }
  }

  .button-and-search {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
    margin-top: 50px;

    &:hover {
      box-shadow: 10px 10px 5px grey transparent;
    }

    a {
      text-decoration: none;
    }
  }

  .seperator {
    margin: 15px;
    height: 20px;
  }

  .table-wrapper {
    width: 80%;
    background-color: white;
    padding: 0 10px;
    margin-left: 10%;
  }
}

.error-message {
  margin-bottom: 13px;
  color: $lava;
  text-align: center;
}

.error-message-disabled {
  margin-bottom: 13px;
  visibility: hidden;
}

@media (min-width: 500px) {
  .form-wrapper {
    width: 80%;
  }
}

@media (min-width: 700px) {
  .form-wrapper {
    width: 60%;
  }
}

@media (min-width: 1100px) {
  .form-wrapper {
    width: 40%;
  }
}
