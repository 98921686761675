.edit-profile-page-wrapper {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  input[type="text"],
  input[type="phone"],
  input[type="email"] {
    width: 250px;
    height: 40px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    border-radius: 10px;
  }
  .banner {
    width: 100vw;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 12%;
  }
  .avatar-wrapper {
    border-radius: 50%;
    align-self: center;
    width: 21vw;
    height: 21vw;
    position: relative;
    top: 120px;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }

  .content-edit-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    background-color: #fafafa;
    width: 100vw;

    .image-uploader-wrapper {
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 3%;

      div {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 10px;
      }
      h1 {
        margin-bottom: 0px;
        font-size: 14px;
        padding: 10px 0px;
      }
      input {
        margin-left: 10px;
        width: 22%;
        border: none;
        padding: 10px 0px;

        &:hover {
          cursor: pointer;
        }
      }

      .remove-button {
        width: 125px;
        height: 30px;
      }
    }

    #banner-upload {
      z-index: 1;
    }

    .image-upload::-webkit-file-upload-button {
      display: none;
    }

    .image-upload::before {
      margin-right: 10px;
      width: 100%;
      content: "Upload Image";
      color: black;
      background-color: white;
      border: 1px solid black;
      border-radius: 10px;
      padding: 5px 8px;
      cursor: pointer;
    }

    .contact-form-wrapper {
      position: relative;
      width: 40vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: -50px;
      padding: 0px;

      .title {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
      }

      .bio {
        font-family: "Poppins", sans-serif;
        position: relative;
        left: 12px;
        width: 40vw;
        height: 20%;
        padding: 10px;
        margin-bottom: 20px;
        border-radius: 10px;
        border: 1px solid black;
      }
    }

    .names-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;

      .name-input {
        width: 90%;
      }

      #last-name {
        margin-left: 10%;
      }
    }
    .info-wrapper {
      display: flex;
      flex-direction: column;
      align-items: space-between;
      justify-content: center;
      width: 100%;

      input {
        margin-top: 20px;
        width: 100%;
        height: 40px;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        border-radius: 10px;
      }

      .small-address-fields {
        width: 42vw;
        display: flex;
        justify-content: space-between;

        input {
          width: 25%;
        }
      }
    }

    .contact-methods-wrapper {
      span {
        width: 31%;
        border-bottom: 1px solid #707070;
        margin: 0px 8px 0px 8px;
      }

      .allow-contact-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        h1 {
          margin: 0px 4px 0px 4px;
        }
      }
    }
    .phone-number-wrapper {
      text-align: center;
    }

    span {
      width: 78%;
      border-bottom: 1px solid #707070;
    }

    .action-buttons-wrapper {
      display: flex;
      justify-content: center;
      padding-top: 25px;
    }
    .social-media-icons {
      width: 100%;
    }
    .social-media-update {
      display: flex;
      justify-content: center;
    }
  }
  input {
    border: 1px solid black;
    padding-left: 20px;
  }
}

.success-message-active {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  color: #237a3b;
  padding-bottom: 10px;
}

.success-message-inactive {
  padding-top: 10px;
  visibility: hidden;
}

.fail-message-active {
  display: flex;
  justify-content: center;
  font-size: 15px;
  padding-top: 10px;
  color: rgb(163, 33, 33);
}

.fail-message-inactive {
  padding-top: 10px;
  visibility: hidden;
}
