.confirm-merge-page-wrapper {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .logo-wrapper {
      img {
        width: 110px;
        height: 74px;
      }
    }
    .confirm-wrapper {
      width: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .p-wrapper {
          padding-top: 20px;
        p {
            font-size: 20px;
            text-align: center;
        }
      }
      .buttons-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .confirm {
            background-color: rgb(0, 138, 0);
            position: relative;
            margin: 10px;
            top: 20px;
            padding: 8px 0px;
            font-size: 16px;
            width: 200px;
            border: 1px solid #605b56;
        }
        .deny {
            background-color: rgb(165, 0, 0);
            position: relative;
            margin: 10px;
            top: 20px;
            padding: 8px 0px;
            font-size: 16px;
            width: 200px;
            border: 1px solid #605b56;
        }
      }
      .info-wrapper {
          padding-top: 40px;
          width: 600px;
          text-align: center;
          color: #605b56;
      }
    }
  
    @media (max-width: 768px) {
      .confirm-wrapper {
        .info-wrapper {
            width: 300px;
        }
        .buttons-wrapper {
            flex-direction: column;
        }
      }
    }
  
    @media (min-width: 1024px) {
      .logo-wrapper {
        img {
          width: 162px;
          height: 110px;
        }
      }
    }
  }
  