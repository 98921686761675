.search-result-item-container {
  width: 90vw;
  max-height: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #707070;
  transition: 0.2s ease-in-out;

  &:hover {
    -webkit-box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.44);
    box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.44);
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;

    input {
      height: 20px !important;
    }
  }

  .avatar-image-wrapper {
    display: flex;
    align-items: center;

    img {
      border-radius: 50%;
    }
  }

  .search-item-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    width: 40%;

    h2 {
      text-align: center;
    }

    h1 {
      margin: 0px 0px 40px 0px;
      text-align: center;
      color: #18686f;
      font-weight: 400;
    }
  }

  .fas {
    font-size: 50px;
    color: #7ebdc3;

    &:hover {
      cursor: pointer;
      color: #65999e;
    }
  }
}

@media (min-width: 768px) {
  .find-family {
    h1 {
      font-size: 18px;
    }

    input {
      font-size: 16px;
      font-family: "Poppins", sans-serif;
      font-weight: 300;
      width: 22%;
      border-radius: 10px;
      padding: 5px;
      border: 1px solid $medium-grey;
    }

    .search-result-item-container {
      height: 15vh;
      width: fit-content;
      margin: auto;
      margin-top: 30px;
    }

    .avatar-image-wrapper {
      padding: 10px;
      img {
        width: 70px;
        height: 70px;
        margin: 10px;
      }
    }

    .checkbox-wrapper {
      .search-result-checkbox {
        width: 20px;
        height: 20px;
      }

      &:hover {
        color: black;
      }
    }

    .search-item-info {
      padding: 10px;

      h2 {
        font-size: 20px;
        margin: 0;
        margin-right: 15px;
        padding: 5px 0;
      }

      h1 {
        font-size: 16px;
        margin: 0;
        margin-right: 15px;
        padding: 5px 0;
        white-space: nowrap;
      }
    }
  }

  .relationship-wrapper {
    display: block;
    text-align: center;

    select {
      border: 1px solid #a0a0a0;
      padding: 5px;
      border-radius: 10px;
      font-family: "Poppins", sans-serif;
      font-weight: 300;
    }

    button {
      margin-left: 10px;
      height: 34px;
      width: 50px;
    }
  }

  .search-result-item-container {
    padding: 0px 20px;
    width: 43vw;
    max-width: 70vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .checkbox-wrapper {
    .search-result-checkbox {
      width: 25px;
      height: 25px;
    }

    &:hover {
      color: black;
    }
  }

  .avatar-image-wrapper {
    img {
      width: 120px;
      height: 120px;
    }
  }

  .search-item-info {
    h2 {
      font-size: 35px;
      width: 150%;
    }

    h1 {
      font-size: 25px;
    }
  }
}
