.create-list-form {
  text-align: center;

  p {
    text-align: center;
    height: 50px;
    width: 100%;
    margin: 0px;
    font-size: 0.75rem;
  }
}

.custom-name {
  h1 {
    line-height: 1;
    font-size: 20px;
    align-items: center;
    margin-top: 3%;
  }
}

.text-box {
  input {
    width: 75%;
    height: 45%;
    padding: 2px;
    border-radius: 10px 10px 0px 10px;
    border: 1px solid black;
    margin: 3%;
    padding: 3%;
  }
}

.file-upload-banner-image {
  input {
    padding: 15px 0px;
    width: 105%;
  }
}

.image-upload::-webkit-file-upload-button {
  display: none;
  content: none;
  height: 50%;
  width: 50%;
}

.image-upload::before {
  position: normal;
  margin: 20%;
  margin-left: 10%;
  max-width: 105%;
  content: "Upload Image";
  color: black;
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  padding: 5px 8px;
  cursor: pointer;
}

.save-button {
  padding-top: 5px;
}