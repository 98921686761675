.fp-page-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo {
    display: flex;
    justify-content: center;
    width: 50%;
    padding: 10% 0;
  }

  .fp-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    width: 100%;
    margin-bottom: auto;

    .fp-text-wrapper {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 7%;
  
      h2 {
        font-weight: 500;
        font-size: 1.8em;
        margin: .2rem 0;
      }
  
      h3 {
        font-size: 0.8em;
        font-weight: 400;
        margin: .2rem 0;
      }
    }
    
    .form-wrapper {
      width: 50%;
      display: flex;
      flex-direction: column;

      .input-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
  
        input {
          margin: .7rem 0;
          padding: 1rem;
          border: 1px solid $dark-primary;
          border-radius: 10px;
          width: 60%;
    
          &:focus {
            outline: none;
          }
        }
      }

      .btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: .5rem 0;
        
      }
    }
  }
}

@media (min-width: 750px) {
  .fp-text-wrapper {
    h2 {
      font-size: 3em !important;
    }
    h3 {
      font-size: 1.3em !important;
    }
  }
}

@media (min-width: 1200px) {
  .fp-text-wrapper {
    h2 {
      font-size: 2em !important;
    }
    h3 {
      font-size: 1em !important;
    }
  }

  .form-wrapper {
    width: 60%;
    position: relative;
    bottom: 40px;
  }
}
