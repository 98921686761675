.learn-more-page-wrapper {
  padding: 20px 0px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo-wrapper {
    img {
      width: 110px;
      height: 74px;
    }
  }
  .content-wrapper {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: #605b56;
      font-size: 16px;
      text-align: center;
    }
  }
  
  a {
    width: 100%;
    height: 100%;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 1024px) {
    .logo-wrapper {
      img {
        width: 162px;
        height: 110px;
      }
    }
    .content-wrapper {
      width: 50%;

      p {
        font-size: 20px;
      }
    }
  }
}
